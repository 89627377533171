import React from "react"
import Bio from "../components/about/bio"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About"
      />

      <div className="flex flex-col md:justify-center flex-1 ">
        <Bio />
      </div>
    </Layout>
  )
}

export default AboutPage
