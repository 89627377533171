/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const Bio = ({ className }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div className="flex rounded my-8 md:w-full h-auto overflow-hidden">
      <BackgroundImage
        fluid={data.avatar.childImageSharp.fluid}
        className={className}
      >
        <div className="flex-row m-8 w-full md:w-2/5 h-auto text-justify">
          <p className="my-2 py-2 text-xl tracking-widest">
            Our human understanding of each other amongst all things, is through
            storytelling.{" "}
          </p>
          <p className="py-2 my-2">
            A.Wilding writes to bear witness to the expansive worlds created of
            and by authors from everywhere. Exploring works that embrace
            kinship, identity, philosophy, mythology, magic, geography, love,
            loss, and most centrally - life, she hopes to create here an ode to
            words and their incredible power to hold us in true moments of
            togetherness. Above all else, it is a celebration of authors.
          </p>
          <p className="text-white md:text-black py-2 my-2 italic tracking-wide">
            Understanding the weightiness of putting pen to paper leads me to
            forstall my own creative works with a deep reverence for those who
            do so, and do so well. Whilst I map out my own story to tell, I hope
            to share the great many that I have already consumed, pondered over
            and only occasionally given up on. I promise honesty and a kind word
            or two.
          </p>
          <Blendtext className="text-white py-2 my-2 text-xl tracking-widest">
            There is untold joy in the impossibility to read all things.
          </Blendtext>
          <div className="md:text-black text-white py-2 my-2">
            A.Wilding lives in South East London with her partner and
            over-flowing collection of houseplants.
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

const Blendtext = styled.p`
  mix-blend-mode: none;
`

const StyledBio = styled(Bio)`
  display: flex;
  overflow: hidden;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  width: 100%;
  height: auto;
  min-height: 60vh;
  mix-blend-mode: multiply;
  /* You should set a background-size as the default value is "cover"! */
  background-size: cover;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
`

export default StyledBio
